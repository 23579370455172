import type { Supplier } from "@/services/supplier";
import { usePrepurchase } from "@/hooks/use-prepurchase";
import { formatCurrency } from "@/lib/format-currency";
import type { Lang } from "@/types";
import { useTranslations } from "@/i18n";

export const CartSummary = ({
  lang,
  supplier,
}: {
  lang: Lang;
  supplier: Supplier;
}) => {
  const t = useTranslations(lang);
  const { prepurchase } = usePrepurchase({ supplier });

  if (!prepurchase) {
    return null;
  }

  return (
    <div className="text-sm">
      <div className="flex justify-between gap-4">
        <span>{t("purchases.field.subTotalWithDiscounts")}</span>
        <span>
          {formatCurrency(
            prepurchase.subTotalWithDiscounts,
            lang,
            supplier.countryCode,
          )}
        </span>
      </div>
      {prepurchase.shipping > 0 && (
        <div className="flex justify-between gap-4">
          <span>{t("purchases.field.shipping")}</span>
          <span>
            {formatCurrency(prepurchase.shipping, lang, supplier.countryCode)}
          </span>
        </div>
      )}
    </div>
  );
};
